import React from "react"

const Form = () => {
  return (
    <>
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        action="/thank-you"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="flex flex-col md:flex-row mb-2">
          <label className="flex-1 pr-0 md:pr-2">
            <input
              name="name"
              placeholder="Name..."
              className="shadow p-4 bg-offwhite w-full mb-2"
              type="text"
            />
          </label>
          <label className="flex-1 pl-0 md:pl-2">
            <input
              name="email"
              placeholder="Email..."
              className="shadow p-4  bg-offwhite w-full "
              type="text"
            />
          </label>
        </div>
        <label>
          <textarea
            name="message"
            placeholder="Message..."
            className="shadow p-4  bg-offwhite w-full"
            cols="30"
            rows="10"
          ></textarea>
        </label>
        <div className="flex mt-4 justify-end">
          <button
            className="bg-brand text-white hover:bg-dark px-4 py-2 shadow text-xs font-bold tracking-wider uppercase p-1"
            type="submit"
          >
            Send
          </button>
        </div>
      </form>
    </>
  )
}
export default Form
