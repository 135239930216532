import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentLayout from "../components/contentLayout"
import Form from "../components/form"

const Text = () => {
  return (
    <>
      <h1 className="text-brand text-base uppercase tracking-wider font-bold">
        Contact us
      </h1>
      <h2 className="my-10 mb-12 text-4xl font-bold leading-snug">Coffee?</h2>
      <p className="mb-10">
        Email us at{" "}
        <a
          className="underline text-black hover:text-brand"
          href="mailto:hello@redgarden.co.nz"
        >
          hello@redgarden.co.nz
        </a>{" "}
        or send us an message below and we'll get back to you right away.
      </p>
      <h4 className="mb-2 font-bold text-sm uppercase text-brand">Address</h4>
      <p className="mb-10">
        We're based in Point Chevalier, Auckland, New Zealand.
      </p>
      <Form />
    </>
  )
}

const Contact = ({ location }) => {
  return (
    <Layout
      location={location}
      header={`dark`}
      image="https://images.unsplash.com/photo-1489533119213-66a5cd877091?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"
    >
      <SEO
        title="Contact us"
        description={`hello@redgarden.co.nz - Contact Redgarden at for graphic design, branding, logos, websites, mobile apps, video production, PR, print and more.`}
      />
      <ContentLayout
        featureImage={`https://images.unsplash.com/photo-1449247709967-d4461a6a6103?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1502&q=80`}
      >
        <Text />
      </ContentLayout>
    </Layout>
  )
}

export default Contact
